const LOCALE_TO_LOCALE = {
  "zh-hant-hk": "en-hk",
  "zh-hant-tw": "en-tw",
  "zh-hans-sg": "en-sg",
  ko: "en-kr",
  ja: "en-jp",
  hi: "en-in",
  "ar-ae": "en-ae",
  eu: "en-eu",
  "ms-my": "en-my",
  "sv-se": "en-se",
  "ar-qa": "en-qa",
  "ur-pk": "en-pk",
  "id-id": "en-id",
  "he-il": "en-il",
  "pt-br": "en-br",
  "es-ar": "en-ar",
  "es-mx": "en-mx",
  "es-cl": "en-cl",
  fr: "en-fr",
}

export default (locale) => {
  return LOCALE_TO_LOCALE[locale.toLowerCase()] || locale
}
